import React, { useEffect } from "react"
import { graphql } from "gatsby"
import remark from "remark"
import recommended from "remark-preset-lint-recommended"
import remarkHtml from "remark-html"
import scrollTo from "gatsby-plugin-smoothscroll"
import SEO from "../components/seo"
import Header from "../components/header"
import Footer from "../components/footer"
import "../styles/services.scss"

export default function Pages({ data }) {
  const toHTML = value =>
    remark().use(recommended).use(remarkHtml).processSync(value).toString()

  function createMarkup(data) {
    const dataHtml = toHTML(data)

    return { __html: dataHtml }
  }
  const post = data.services

  useEffect(() => {
    scrollTo("#page-top")
  })

  return (
    <>
      <SEO
        title={post.frontmatter.title}
        description={post.frontmatter.details}
        imageName={post.frontmatter.id}
      />
      <Header />
      <main id="page-top">
        <section className="main-service">
          <div className="container">
            <div className="service-content">
              <div className="service-text">
                <h2>{post.frontmatter.description}</h2>
                <div
                  dangerouslySetInnerHTML={createMarkup(post.internal.content)}
                >
                  {}
                </div>{" "}
                <h3>{data.contact.frontmatter.contact_text}</h3>
              </div>
            </div>
            <div className="service-button">
              <button onClick={() => scrollTo("#contact")}>
                {data.contact.frontmatter.cta_button_text}
              </button>
            </div>
          </div>
        </section>
      </main>
      <Footer id="contact" selected={post.frontmatter.id} />
    </>
  )
}

export const query = graphql`
  query($slug: String!) {
    services: markdownRemark(fields: { slug: { eq: $slug } }) {
      internal {
        content
      }
      frontmatter {
        title
        description
        details
        id
      }
    }
    contact: markdownRemark(fileAbsolutePath: { regex: "/homepage.md/" }) {
      frontmatter {
        contact_text
        cta_button_text
      }
    }
  }
`
